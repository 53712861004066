import React from "react"
import { graphql } from "gatsby"
import GraphQLErrorList from "../components/graphql-error-list"
import SEO from "../components/seo"
import Layout from "../containers/layout"
import PortableText from "../components/portableText"

export const query = graphql`
    query PageTemplateQuery($id: String!) {
        page: sanityPage(id: { eq: $id }) {
            title
            _rawBody
        }
    }
`

const PagePostTemplate = (props) => {
    const { data = {}, errors } = props
    const { title, description, _rawBody } = data.page || {}

    return (
        <Layout>
            {errors && <GraphQLErrorList errors={errors} />}
            {!data.page && <p>No page data</p>}
            <SEO title={title} description={description} />
            <article>
                {/* <h1>{title}</h1>
                    <p>{description}</p> */}
                {_rawBody && <PortableText blocks={_rawBody} />}
            </article>
        </Layout>
    )
}

export default PagePostTemplate
